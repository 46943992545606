.home-container {
    text-align: center;
    padding: 20px;
  }
  
  .home-container p {
    font-size: 24px;
    line-height: 1.6;
    margin-bottom: 20px;
    
  }

  p {
    font-size: 24px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .large-text {
    font-size: 24px;
    color: #3c763d;
  }
  .extra-large-text {
    font-size: 32px;
    font-weight: bold;
    color: #ff4500;
  }

  /* Unordered list styling */
  ul {
    list-style-type: none; /* Remove default bullet points */
    padding: 0;
    vertical-align: middle;
    width: 60%;
  }

  /* List item styling */
  li {
    font-size: 16px;
    margin: 10px 0;
    padding: 10px;
    background: #eaf7f7;
    color: #005f5f;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    
  }

  /* Hover effect for list items */
  li:hover {
    background: #d4eeee;
    cursor: pointer;
  }

  .bullet-points {
    list-style-type: none; /* Remove default bullet points */
    padding: 0;
    align-items: flex-end;
    align-content: center;

  }

  .center-box {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    text-align: center;
  }
  .bullet-points .service {
    font-size: 16px;
    margin: 10px 0;
    padding: 40px 10px;
    background: #d3c9f0;
    color: #011616;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    text-align: center;
    width: 60%;
    align-items: flex-end;
    align-content: center;
    display: block;
    width: 1800px;
    
  }

  .bullet-points .service .small-box {
    font-size: 14px;
    padding: 5px 10px;
    background: #eaf7f7;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  .image-gallery {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .image-item {
    text-align: center;
  }
  
  .image-item img {
    width: 100%;
    height: auto;
    overflow: hidden;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
  }
  
  .image-item img:hover {
    transform: scale(1.05);
  }
  
  .image-item p {
    margin-top: 10px;
    font-size: 1.2em;
    color: #333;
  }
  