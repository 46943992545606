body {
  
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color:rgb(189, 235, 220);
  justify-content: center;
  align-items: center;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  border-bottom: 1px solid #ddd;
  width: 800px;
}


.logo-container {
  display: flex;
  align-items: left;
  width: 800px;
}

.logo {
  height: 100px; /* Adjust based on your design */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.nav-links li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #007bff;
}

.App {
  text-align: center;
  padding: 20px;
}

.nav-bar {
  padding: 10px;
  background-color: green;
}

.nav-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: left;
}

.nav-bar ul li {
  margin: 0 15px;
  background-color: rgb(136, 217, 245);
}

.nav-bar ul li a {
  color: black;
  text-decoration: coral;
  font-size: 1.2em;
}

.nav-bar ul li a:hover {
  text-decoration: underline;
}

h1 {
  color: #0f0101;
  font-size: 2.5em;
}

p {
  font-size: 1.2em;
  color: #555;
}
p span { 
  display: block; 
} 

/* Popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
